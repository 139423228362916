import React from "react";
import { ErrorMessage } from '@hookform/error-message';

export const Field = ({ children, label, info, ...props}) => {
  const id = getChildId(children);

  return (
    <div {...props}>
      <label htmlFor={id} className="form-label">
        {label} {info?(
          <i className="text-secondary bi bi-info-circle-fill" data-toggle="tooltip"
            data-placement="top" title={info}></i>):null}
      </label>
      {children}
      <ErrorMessage name={id}
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p className="text-danger" key={type}>{message}</p>
          ))
        }
      />
    </div>
  );
};

// Get id prop from a child element
export const getChildId = (children) => {
  const child = React.Children.only(children);

  if ("id" in child?.props) {
    return child.props.id;
  }
};
