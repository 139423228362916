import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../state";
import { Button, Form, Section } from "../components";
import { fillDefaultValuesForSection } from '../utils';

export default function GeneratedInputPage({ structure, submitBtnRef }) {
  const [state, setState] = useAppState();
  const [updated, setUpdated] = useState(false);
  const formDefaults = fillDefaultValuesForSection(state.inputs, structure.key, structure);
  const methods = useForm({
    mode: "onSubmit", criteriaMode: "all",
    defaultValues: {
      ...state,
      "inputs": {
        ...state.inputs,
        ...formDefaults}
    }
  });

  const navigate = useNavigate();

  const saveData = (data) => {
    // Only inputs from generated pages
    setState({ ...state,
      "inputs": { ...state.inputs, ...data.inputs},
      "error": { ...state.error, [`${structure.key}`]: false }});
    setUpdated(true);
  };

  const validationError = (errors) => {
    setState({ ...state, "error": { ...state.error, [`${structure.key}`]: true }});
  }

  // Removes empty objects from object tree
  const removeEmpty = (inputObj) => {
    Object.keys(inputObj).forEach(k => {
      if (inputObj[k]) {
        if (typeof inputObj[k] === 'object' && removeEmpty(inputObj[k]) === null) {
          delete inputObj[k];
        } else if (Array.isArray(inputObj[k])) {
          if (inputObj[k].every(element => element === null)) {
            delete inputObj[k];
          }
        }
      }
    });

    if (!Object.keys(inputObj).length) {
      return null;
    }
  }

  useEffect(() => {
    if (!updated) return;
    if (structure.next) {
      navigate("/" + structure.next);
    } else {
      // Pass API inputs via 3 checks to clean it up
      // TODO: Find another way to not have these extra data collected here
      var apiInput = JSON.stringify(state, (k, v) => {
        if (k == "error" || k == "saving") return undefined;
        if (v == null) return undefined;
        if (typeof v === 'object' && v !== null) {
          if (typeof(v.saved) != "undefined" && v.saved === false) {
            return undefined;
          }
        }
        return v;
      });
      const jsoncheck = JSON.parse(apiInput);
      // Remove empty elements thrice for clean input to API
      removeEmpty(jsoncheck);
      apiInput = JSON.stringify(jsoncheck);
      // Console logs for development mode
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // Log API output
        console.log(apiInput);
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: apiInput
      };

      fetch(process.env.REACT_APP_BACKEND_URL + '/calculate', requestOptions)
        .then(response => response.json())
        .then(data => {
          navigate("/analysis", {state: data});
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  return (
    <FormProvider {...methods}>
      <Form className="container-lg"
        onSubmit={methods.handleSubmit(saveData, validationError)}>
        <legend>{structure.title}</legend>
          { structure.sections.map((section) => {
            return <Section desc={section} level={0}
              tkey={"inputs." + structure.key + "." + section.key} key={structure.key + "." + section.key} />
          })}
        <div className="mt-3 me-n2 float-end">
          {structure.previous?
            <NavLink to={"/" + structure.previous} rel="noopener noreferrer" className="pe-2">
              <Button variant="">Back</Button>
            </NavLink>
            :null
          }
          <Button type="submit" ref={submitBtnRef} variant="primary">
            {structure.next? "Next": "Submit"}
          </Button>
        </div>
        <div className="clearfix"></div>
      </Form>
    </FormProvider>
  );
}
