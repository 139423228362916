import { React } from 'react';
import { useFieldArray, useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { rupeesToText } from '../utils';

export const FieldArray = ({ desc, tkey}) => {
  // TODO: Heuristics based hack. Must be revisited in free time
  const { register, control, trigger, getValues, setValue} = useFormContext();

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: `${tkey}`,
    defaultValues: {
      [`${tkey}`]: [{"saved": false, "saving": false}]
    },
    rules: {
      validate: {
        minEntries: value => {
          if (desc.minEntries) {
            if ((!Array.isArray(value)) || (value.filter((row) => row.saved).length < desc.minEntries.value)) {
              return desc.minEntries.message;
            }
          }
          return true;
        }
      }
    }
  });

  // Render the component
  return <div className="row gy-1">
    <ul>
      { fields.map((item, index) => {
        // className={`${desc.className} form-floating ${saved?"border rounded":null}`}
        return <li key={item.id}
          className={`row mt-3 mx-1 ${item.saved?"border border-secondary rounded":null}`}>
            {desc.fields.map((field) => {
              // Fields for values entered by customers
              return <FieldArrayItem desc={field} key={tkey + `.${index}.${field.key}}`} tkey={tkey} 
                index={index} saved={item.saved} saving={item.saving}></FieldArrayItem>;
            })}
            <input type="hidden" id={tkey+"."+index+"."+"saved"}
                {...register(tkey + `.${index}.saved`)} />
            <input type="hidden" id={tkey+"."+index+"."+"saving"}
                {...register(tkey + `.${index}.saving`)} />
            {item.saved?(
              <div className="col-lg-2 text-end pt-3">
                <button type="button" className="btn btn-link text-start text-decoration-none"
                onClick={() => {remove(index)}}><i className="bi bi-x-lg"></i></button>
              </div>
            ):(
              <div className="col-lg-2 text-end pt-3">
                { fields.length <=1? " ": (
                  <button type="button" className="btn btn-link text-start text-decoration-none"
                  onClick={() => {remove(index)}}>Cancel</button>
                )}
                <button type="button" className="btn btn-link text-start text-decoration-none"
                onClick={async () => {
                  setValue(`${tkey}.${index}.saving`, true);
                  update(index, getValues(`${tkey}.${index}`));
                  await trigger(tkey);
                  const allFields = desc.fields.map((field) => {
                    return `${tkey}.${index}.${field.key}`;
                  });
                  const result = await trigger(allFields);
                  if (result) {
                    setValue(`${tkey}.${index}.saved`, true);
                    update(index, getValues(`${tkey}.${index}`));
                    await trigger(tkey);
                  }
                }}>Save</button>
              </div>
            )}
          </li>;
        })}
    </ul>
    <div className='pb-2 pt-3'>
      { fields.filter((row) => !row.saved).length >= 1?null:(
        <button style={{border:'none', background:'white'}}
        type="button" className="btn-link text-start text-decoration-none "
        onClick={() => append({"saved": false, "saving": false})}
        ><i className="bi bi-plus"></i> Add
      </button>
      )}
    </div>
    <ErrorMessage name={`${tkey}.root`}
      render={({ messages }) =>
        messages &&
        Object.entries(messages).map(([type, message]) => (
          <p className="text-danger" key={type}>{message}</p>
        ))
      }
    />
  </div>;
}

export const FieldArrayItem = ({desc, index, tkey, saved, saving}) => {
  const { register, getValues, setValue, errors } = useFormContext();

  const onSelectChange = (event) => {
    const selector = event.target;
    const selectedValue = selector.value || selector.options[selector.selectedIndex].value;
    desc.options.map((option) => {
      if (option.value == selectedValue) {
        setValue(`${tkey}.${index}.cagr`, option.cagr);
      }
    });
  };

  // TODO: The field types should not be limited to select and non-select
  // className={`${desc.className} form-floating ${saved?"border rounded":null}`}
  // {`form-control-plaintext shadow-none border-0 ${saved?null:"border-bottom"}`}
  return <div className={desc.className + " form-floating"} key={tkey}>
    {desc.type=="select"?
      (
        <>
        <select className={`form-select shadow-none border-0 ${saved?null:"border-bottom"}`}
          {...register(tkey + `.${index}.${desc.key}`, (saving && desc.validations)?(desc.validations):{})}
          id={`${tkey}.${index}.${desc.key}`}
          readOnly={saved} disabled={saved} onChange={onSelectChange}
          >
          <option value="" key="placeholder" hidden>{desc.placeholder?desc.placeholder:desc.label}</option>
          {desc.options.map((option) => {
            // Show option only if it's either saved, has multiple allowed, or isn't yet used up
            if ((saved) || (!option.useOnce) || !getValues(tkey)?.find(row => row.saved && row[desc.key]===option.value)) {
              return <option value={option.value} key={option.value}>{option.label}</option>;
            }
          })}
        </select>
        <label htmlFor={`${tkey}.${index}.${desc.key}`}>{desc.label}</label>
        <ErrorMessage name={`${tkey}.${index}.${desc.key}`}
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p className="text-danger" key={type}>{message}</p>
            ))
          }
        />
        </>
      ):(
        <>
        <input className={`form-control shadow-none border-0 hide-spinner ${saved?null:"border-bottom"}`}
        placeholder="test" type={desc.type} onWheel={ event => event.currentTarget.blur() }
        readOnly={saved} disabled={saved}
        id={`${tkey}.${index}.${desc.key}`}
        aria-invalid={`${errors}.${tkey}.${index}.${desc.key}` ? "true" : "false"}
        {...register(`${tkey}.${index}.${desc.key}`, (saving && desc.validations)?(desc.validations):{})}  
        onKeyDown={e => desc.notAllowed?.includes(e.key) && e.preventDefault()}
        onInput={e => {
          const in_words = document.getElementById(`${tkey}.${index}.${desc.key}.inwords`);
          if (in_words) {
            const numVal = Number(e.target.value);
            in_words.innerText = isNaN(numVal)?'':rupeesToText(numVal);
          }
        }} />
        <label htmlFor={`${tkey}.${index}.${desc.key}`}>{desc.label}</label>
        {desc.isCurrency?(
          <span key={tkey + "." + index + "." + desc.key + ".inwords"} className="text-primary"
            id={tkey + "." + index + "." + desc.key + ".inwords"}></span>
          ):null}
        <ErrorMessage name={`${tkey}.${index}.${desc.key}`}
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p className="text-danger" key={type}>{message}</p>
            ))
          }
        />
        </>
      )}
  </div>;
}