import React from "react";
import ReactDOM from "react-dom/client";
import './styles/styles.scss';
import 'bootstrap-icons/font/bootstrap-icons.scss';
import { App } from "./app";
import reportWebVitals from './reportWebVitals';

import "./i18n";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <App />
    </React.Suspense>
  </React.StrictMode>
);

reportWebVitals();
