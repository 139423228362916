import React from 'react';
import { FieldArray } from './fieldarray';

export const Section = ({ desc, level, tkey}) => {
  return (
    <div className={`container-lg rounded p-4 mb-3 ${level==1?null:"border"}`}>
      {level==0?(
        <h5 className="text-primary">{desc.label}</h5>
      ):(
        <>
        <h5 className="text-body">{desc.label}:</h5>
        <hr className="text-primary" />
        </>
      )}
      {desc.sections?(
        desc.sections.map((section) => {
          const sectionKey = tkey + "." + section.key;
          return <Section level={level+1} desc={section} key={sectionKey} tkey={sectionKey}>
            {section.fields?(
              <FieldArray desc={section} key={sectionKey} tkey={sectionKey} />
            ):null}
          </Section>
        })
      ):null}
      {desc.fields?(
        <FieldArray desc={desc} key={tkey} tkey={tkey} />
      ):null}
    </div>
    );
};
