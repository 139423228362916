import React from 'react';
import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { Field } from './field';
import { Input } from './input';

export const RadioButtonSet = forwardRef(
  ({ data, ...props }, ref) => {
    const { errors, register } = useFormContext();

    return (
      <div className="d-flex flex-row flex-wrap mx-n1">
      {data.options.map(({ value, label}, index) => {
        return (
          <Field 
            key={data.fieldname + "_" + index} className="p-1 flex-fill d-grid col-lg-2 col-sm-12">
            <>
            <Input {...register(data.fieldname)} value={value}
              id={data.fieldname + "_" + index} type="radio" className="btn-check"
            />
            <label htmlFor={data.fieldname + "_" + index} className="btn btn-outline-primary text-start">
              {data.icon?(<i className={"text-primary bi bi-" + data.icon}></i>):null} {label}
            </label>
            </>
          </Field>
        );
      })}
      </div>
    );
  }
);
