// Rupee to text
export function rupeesToText(value) {
  var inrFormat = {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR'
  };

  var val = Math.abs(value);
  if (val >= 10000000) {
    val = val / 10000000;
    return val.toLocaleString('en-IN', inrFormat) + " Cr";
  }
  if (val >= 100000) {
    val = val / 100000;
    return val.toLocaleString('en-IN', inrFormat) + " Lac";
  }
  if (val >= 1000) {
    val = val / 1000;
    return val.toLocaleString('en-IN', inrFormat) + " Thousand";
  }

  inrFormat.maximumFractionDigits = 0;
  return value.toLocaleString('en-IN', inrFormat);
}

/**
 * Returns default values for section of inputs definition
 * @param {*} state The input state from API, or empty state if first session
 * @param {*} tkey The key for the incoming desc parameter
 * @param {*} desc Section description object from the inputs definition file
 * @returns Default values for section
 */
export function fillDefaultValuesForSection(state, tkey, desc) {
  const existing = (state && (tkey in state))?state[`${tkey}`]:undefined;
  var defaultValues = existing;
  if (desc.sections) {
    desc.sections.map((subsection) => {
      const existingSubsection = (existing && (subsection.key in existing))?existing[`${subsection.key}`]:undefined;
      defaultValues = {
        ...defaultValues,
        ...fillDefaultValuesForSection(defaultValues, subsection.key, subsection)
      }
    });
  } else {
    if (desc.fields) {
      if (!existing) {
        defaultValues = [{"saving": false, "saved": false}];
      }
    }
  }
  return { ...state, [`${tkey}`]: defaultValues};
}