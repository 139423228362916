import { React } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import { Button } from "./components";
import { rupeesToText } from './utils';

import "./styles/graphAnalysis.css"

export const AnalysisPage = ({ }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToPreviousPage = () => {
    navigate(-1);
  };

  // Console logs for development mode
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // Log API output
    console.log(location.state.wealth);
  }

  // Add a plugin to draw vertical line on hover
  ChartJS.register({
    id: 'drawVerticalLine',
    afterDraw: (chart) => {
      if (chart.tooltip?._active?.length) {
        let x = chart.tooltip._active[0].element.x;
        let yAxis = chart.scales.y;
        let ctx = chart.ctx;
        ctx.save();
        ctx.setLineDash([3, 3]);
        ctx.beginPath();
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#000000';
        ctx.stroke();
        ctx.restore();
      }
    }
  });

  // Chart initialization
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors);
  const options = {
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          precision: 0,
          callback: function (value, index, values) {
            return value / 1e5;
          }
        },
        title: {
          display: true,
          text: 'Amount in Lakhs (₹)',
          font: {
            family: 'Roboto',
            size: 14,
            style: 'normal',
          },
          padding: {
            bottom: 25
          }
        },
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          precision: 0
        },
        title: {
          display: true,
          text: 'Years',
          position: 'bottom',
          font: {
            family: 'Roboto',
            style: 'normal',
            size: 14
          },
        },
      },
    },
    tooltipTemplate: "<%if (label){%><%=label %>: <%}%><%= value + ' %' %>",
    multiTooltipTemplate: "<%= value + ' %' %>",
    responsive: true,
    tension: "0.2",
    pointRadius: "0",
    borderWidth: "2",
    hoverPointRadius: "0",
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 15,
          font: {
            family: 'Roboto',
            style: 'normal',
            family: 'Inter',
            size: 14
          },
        }
      },
      title: {
        display: true,
        align: 'start',
        text: '',
        padding: {
          top: 10,
          bottom: 30
        }
      },
      tooltip: {
        enabled: true,
        backgroundColor: "white",
        bodyColor: "black",
        titleColor: 'black',
        boxPadding: 6,
        bodySpacing: 5,
        borderWidth: 1,
        borderColor: 'rgb(207, 204, 204)',
        backgroundColor: 'rgb(250, 250, 250)',
        callbacks: {
          title: function (datapoints) {
            // Return age with number, or nothing for default rendering
            if (datapoints.length > 0) {
              return "Age: " + datapoints[0].label + " years";
            }
          },
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ": " + rupeesToText(tooltipItem.parsed.y);
          }
        }
      }
    },
  };

  var datasets = new Array();
  // Returns child nodes and data sets for plots from API output nodes
  const createDataSets = (node) => {
    var childNodes = new Array();
    var datapoints = new Array();
    var x_axis = new Array();
    Object.keys(node).forEach(element => {
      if (Object.keys(node[element].components).length > 0) {
        childNodes.push({
          name: element,
          components: node[element].components
        });
      }
      x_axis =
        Object.keys(node[element].time_value_series).length < x_axis.length ?
          x_axis
          : (Object.keys(node[element].time_value_series));
      datapoints.push({
        label: t(`analysis.${element}`),
        data: node[element].time_value_series,
      });
    });
    return [childNodes, { labels: x_axis, datasets: datapoints }];
  };

  var [child_nodes, root_node] = createDataSets(location.state.wealth);
  // Must have sequence of income, expenses, assets, liabilities
  const known_nodes = ['incomes', 'expenses', 'assets', 'liabilities'];
  child_nodes.sort((a, b) => {
    return known_nodes.indexOf(a.name) - known_nodes.indexOf(b.name);
  });

  return (
    <div className="container-lg rounded bg-white">
      <div className="p-4">
        <h5><b>Your Financial Snapshot</b></h5>
        <p>One glance at the graphs below will give you an understanding of your financial position – currently and in the future for the time duration mentioned by you on the first screen. 
Getting your asset allocation right goes a long way towards helping you achieve your financial goals.  </p>
      </div>
      <hr className="mx-n5" ></hr>
      <div className="text-with-line pt-3 ps-4">
        <h5 className="text pt-3"><b>Summary</b></h5>
        <div className="line"></div>
      </div>
      <div className="ps-4">
        <p>This is the main chart. The one that shows you how your incomes and assets will offset your expenses and liabilities. How each grow and in the case of loans – are paid out. 
Feel free to explore by hovering over the charts to give you the data for that particular year. If you wish to see only one parameter then feel to click on the others to remove them from the graph – only the parameter you want will remain. 
        </p>
      </div>
      <div className="row col-md-12 p-5">
        <Line key="summary" options={options} data={root_node} />
      </div>
      <div id="graphs_accordion">
        {child_nodes.map(element => {
          const [_sub_nodes, this_node] = createDataSets(element.components);
          const localOptions = {
            ...options,
            plugins: {
              ...options.plugins,
              title: {
                ...options.plugins.title,
                // text: t(`analysis.${element.name}.title`) 
              }
            }
          };
          if (element.name == 'incomes') {
            return <div className="card rounded mb-3" key={element.name}>
              <div className="card-header bg-white analysis-card-header collapsed" id={`${element.name}_heading`}
                data-bs-toggle="collapse" data-bs-target={`#${element.name}_body`}
                aria-expanded="false" aria-controls={`${element.name}_body`}>
                <b>{t('Income Streams')}</b>
              </div>
              <div id={`${element.name}_body`} className="collapse" aria-labelledby={`${element.name}_heading`} data-bs-parent="#graphs_accordion">
                <p className="p-3"> This graph shows you the growth of the income steams as per the years mentioned by you in the Income screen. At one glance you can see the growth in your income streams over the years for the duration of the calculation. </p>
                <div className="card-body">
                  <Line key={element.name} options={localOptions} data={this_node} />
                </div>
              </div>
            </div>;
          }

          if (element.name == 'expenses') {
            return <div className="card rounded mb-3" key={element.name}>
              <div className="card-header bg-white analysis-card-header collapsed" id={`${element.name}_heading`}
                data-bs-toggle="collapse" data-bs-target={`#${element.name}_body`}
                aria-expanded="false" aria-controls={`${element.name}_body`}>
                <b>{t(`Expense Timeline`)}</b>
              </div>
              <div id={`${element.name}_body`} className="collapse" aria-labelledby={`${element.name}_heading`} data-bs-parent="#graphs_accordion">
                <p className="p-3">While building a financial plan it is very important to keep a close eye on the expenses and how they will pan out over the years. This chart lets you know the extent of growth of expenses and therefore how much you need to invest to meet these expenses over time 
                </p>
                <div className="card-body">
                  <Line key={element.name} options={localOptions} data={this_node} />
                </div>
              </div>
            </div>;
          }

          if (element.name == 'assets') {
            return <div className="card rounded mb-3" key={element.name}>
              <div className="card-header bg-white analysis-card-header collapsed" id={`${element.name}_heading`}
                data-bs-toggle="collapse" data-bs-target={`#${element.name}_body`}
                aria-expanded="false" aria-controls={`${element.name}_body`}>
                <b>{t(`Asset Growth`)}</b>
              </div>
              <div id={`${element.name}_body`} className="collapse" aria-labelledby={`${element.name}_heading`} data-bs-parent="#graphs_accordion">
                <p className="p-3"> One of the main aspects of investing is to see how the assets you have invested in today, will grow over time. One quick look at the chart and you can see the growth in assets over the period specified by you, taking into account the growth rate of each asset. 

                </p>
                <div className="card-body">
                  <Line key={element.name} options={localOptions} data={this_node} />
                </div>
              </div>
            </div>;
          }

          if (element.name == 'liabilities') {
            return <div className="card rounded mb-3" key={element.name}>
              <div className="card-header bg-white analysis-card-header collapsed" id={`${element.name}_heading`}
                data-bs-toggle="collapse" data-bs-target={`#${element.name}_body`}
                aria-expanded="false" aria-controls={`${element.name}_body`}>
                <b>{t(`Loan Amortization Schedule`)}</b>
              </div>
              <div id={`${element.name}_body`} className="collapse" aria-labelledby={`${element.name}_heading`} data-bs-parent="#graphs_accordion">
                <p className="p-3">The one graph which all investors would like to see going down to zero is the loan payment graph. This graph shows you the loans you have availed of and when you will be able to repay them fully. The progression is calculated using our internal formula, based on the loan amount and EMI entered by you in the calculation.
                </p>
                <div className="card-body">
                  <Line key={element.name} options={localOptions} data={this_node} />
                </div>
              </div>
            </div>;
          }

        })}
      </div>
      <div className="col-sm-12 p-2" style={{ textAlign: "right" }}>
        <Button variant="" onClick={navigateToPreviousPage} >Back</Button>
      </div>
    </div>
  );
};