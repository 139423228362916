import React from 'react';

import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../state";
import { Button, Field, Form, Input, RadioButtonSet } from "../components";


export const BasicsInputPage = ({ globals, submitBtnRef }) => {
  const [state, setState] = useAppState();
  const methods = useForm({ defaultValues: state, mode: "onSubmit", criteriaMode: "all" });

  const navigate = useNavigate();

  const saveData = (data) => {
    setState({ ...state, ...data, "error": { ...state.error, profile: false } });
    navigate("/incomes");
  };

  const validationError = (errors) => {
    setState({ ...state, "error": { ...state.error, profile: true }});
  }

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <FormProvider {...methods}>
      <Form className="container-lg pt-3 px-5" onSubmit={methods.handleSubmit(saveData, validationError)}>
      <legend >Basic Information</legend>
      <p>Please provide below basic details </p>
      <fieldset className="row border rounded pb-3 mb-3">
        <Field label="Current Age" className="col-lg-4 col-sm-12 text-primary mt-3"> 
          <Input
            {...methods.register("current_age",
              {
                min: { "value": 18, "message": "Must be greater than 18"}, 
                max: { "value": 100, "message": "Maximum age supported is 100"},
                required: "Current age is required"})}
            id="current_age" type="number"  onWheel={ event => event.currentTarget.blur()  }
            onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          />
        </Field>
        <Field label="Retirement Age" info="Age at which you wish to retire, or your primary source of income stops from this year"
          className="col-lg-4 col-sm-12 text-primary mt-3" >
          <Input
            {...methods.register("retirement_age",
              {
                validate: {
                  min: value => {
                    if (!value) return;
                    const inputVal = Number(value);
                    const curr_age = methods.getValues("current_age");
                    if (inputVal < Number(curr_age)) {
                      return "Must be more than current age";
                    }
                    if (inputVal < 20) {
                      return "Must be more than 20";
                    }
                    return true;
                  },
                },
                max: { "value": 100, "message": "Maximum retirement age is 100"},
                required: "Retirement age is required"})}
            id="retirement_age" type="number"  onWheel={ event => event.currentTarget.blur() }
            onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          />
        </Field>
        <Field label="Number of years for calculation" info="Please enter the number of years for which you wish your wealth navigator to run. E.g., if you wish to know your financial position 20 years from now, please enter “20”."
          className="col-lg-4 col-sm-12 text-primary mt-3">
          <Input
            {...methods.register("number_of_years",
              {
                min: { "value": 1, "message": "Must be more than 1"}, 
                max: { "value": 100, "message": "Must be less than 100"},
                required: "Number of years is required"})}
            id="number_of_years" type="number"  onWheel={ event => event.currentTarget.blur() }  
            onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          />
        </Field>
        { globals.map((global) => {
            return <input type="hidden" key={global.key} {...methods.register(global.key)} value={global.value} />
        })}
      </fieldset>
      <fieldset className="row border rounded pb-3 mb-3">
        <legend className='pt-4 ps-4' style={{fontSize:'17px'}}>How many members are in your household?</legend>
        <RadioButtonSet
          data={{
            "fieldname": "household_size",
            "icon": "person-fill",
            "options": [
              {"label":"1 Member","value":1},
              {"label":"2 Members","value":2},
              {"label":"3 Members","value":3},
              {"label":"4 Members","value":4},
              {"label":"More than 4 members","value":5},
            ]
          }}
        />
      </fieldset>
      <fieldset className="row border rounded pb-3 mb-3">
        <legend className='pt-4 ps-4' style={{fontSize:'17px'}}>How many dependent members in your household?</legend>
        <RadioButtonSet
          data={{
            "fieldname": "dependents",
            "icon": "person-fill",
            "options": [
              {"label":"1 Member","value":1},
              {"label":"2 Members","value":2},
              {"label":"3 Members","value":3},
              {"label":"4 Members","value":4},
              {"label":"More than 4 members","value":5},
            ]
          }}
        />
      </fieldset>
      <fieldset className="row border rounded pb-3 mb-3">
        <legend className='pt-4 ps-4' style={{fontSize:'17px'}}>What is your employment status?</legend>
        <RadioButtonSet
          data={{
            "fieldname": "employment_status",
            "options": [
              {"label":"Employed","value":"employed"},
              {"label":"Self Employed/Business","value":"business"},
              {"label":"Professional","value":"professional"},
              {"label":"Unemployed","value":"unemployed"}
            ]
          }}
        />
      </fieldset>
      <div className="mt-3 me-n2 float-end">
        <Button type="submit" ref={submitBtnRef} variant="primary">Next</Button>
      </div>
      <div className="clearfix"></div>
    </Form>
    </FormProvider>
  );
}