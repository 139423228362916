import { React, useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import local_structure from './data/inputs-default.json';
import { AppProvider } from "./state";
import { Stepper } from "./header";
import { BasicsInputPage } from "./pages/basics";
import GeneratedInputPage from "./pages/generated";
import { AnalysisPage } from "./analysis";

export const App = () => {
  const { t } = useTranslation();
  const [structure, setStructure] = useState({tabs: []});
  const buttonRef = useRef();

  const onStepChange = () => {
    buttonRef.current?.click();
  };

  const getData = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      setStructure(local_structure);
    } else {
      fetch(process.env.REACT_APP_MASTERDATA_URL + '/inputs.json')
      .then(response => response.json())
      .then(data => {
        setStructure(data);
      });
    }
  }

  useEffect(()=> {
    getData();
  },[]);

  return (
    <div className="container-lg">
      <div className="row">
        <div className="col-lg-3 col-sm-12" style={{paddingTop:'25px', paddingBottom:'15px'}}>
          <img src="https://epsilonmoney.com/wp-content/themes/epsilon-money-custom-theme/img/logo-full-white.png" className="pt-5  mx-auto d-block"
            alt="logo" style={{width:'50%'}} />
        </div>
        <div className="col-lg-6 col-sm-12 ps-5" style={{paddingTop:'76px', paddingBottom:'25px'}}>
          <h2 className="title mt-2" style={{textAlign:'center', color:'white', fontSize:'28px'}}>
            {t('Your Wealth Navigator')}
            </h2>
        </div> 
      </div>
      <div className="container-lg rounded bg-white">
        <AppProvider>
          <Router>
            <Routes>
              <Route path="/" element={structure.tabs.length?<Navigate replace to="/profile" />:<div>Loading...</div>} />
              {structure.tabs.map((tab) => {
                if (tab.generated) {
                  return <Route key={tab.key} path={"/" + tab.key} element={
                    <>
                      <div className="pt-5 pb-lg-1 px-lg-3">
                        <Stepper structure={structure} onStepChange={onStepChange} />
                      </div>
                      <hr className="mx-n3 text-secondary" />
                      <div className="container-lg p-lg-3 px-lg-5">
                        <GeneratedInputPage structure={tab} submitBtnRef={buttonRef} key={tab.key} />
                      </div>
                    </>
                    } />;
                } else {
                  // TODO: Move structure of profile page to the json and remove the 'generated' key
                  return <Route key={tab.key} path={"/" + tab.key} element={
                    <>
                      <div className="pt-5 pb-lg-1 px-lg-3">
                        <Stepper structure={structure} onStepChange={onStepChange} />
                      </div>
                      <hr className="mx-n3 text-secondary" />
                      <div className="container-lg p-lg-3 px-lg-5">
                        <BasicsInputPage globals={structure.globals} submitBtnRef={buttonRef} key={tab.key} />
                      </div>
                    </>
                    } />;
                }
              })}
              <Route key="analysis" path="/analysis" element={
                <div className="container-lg p-lg-3 px-lg-4">
                  <AnalysisPage key="analysis" />
                </div>
              } />;
            </Routes>
          </Router>
        </AppProvider>
      </div>
    </div>
  );
};
