import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAppState } from "./state";

export const Stepper = ({ structure, onStepChange }) => {
  const [state] = useAppState();
  const location = useLocation();
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps((steps) => [...steps, location.pathname]);
  }, [location]);

  const getLinkClass = ({ isActive }) =>
    `tablink ${isActive ? "active" : ""}`;

  const isVisited = (key) =>
    steps.includes("/" + key) && location.pathname !== ("/" + key);

  const showSuccess = (key) => {
    return isVisited(key);
  };

  const showWarning = (key) => {
    if (state?.error) {
      return state?.error[key];
    } else {
      return false;
    }
  }

  return (
    <div className="d-flex flex-wrap justify-content-center">
      {structure.tabs.map(({ key, label }) => {
        return (
          <NavLink exact="true" style={{pointerEvents:'none'}}
            to={"/" + key}
            className={getLinkClass} key={key}
            onClick={onStepChange} >
             <StepState
                showWarning={showWarning(key)}
                showSuccess={showSuccess(key)}
              />
            <span className="names">{label}</span>
          </NavLink>
        );
      })}
    </div>
  );
};

const StepState = ({ showWarning, showSuccess }) => {
  if (showWarning) {
    return (
      <i className="bi bi-exclamation-circle-fill text-danger" />
    );
  } else if (showSuccess) {
    return (
      <i className="bi bi-check-circle-fill text-primary" />
    );
  } else {
    return null;
  }
};
